
@font-face {
    font-family: 'orkney';
    font-weight: normal;
    font-style: normal;
    src: url('fonts/orkney/regular.otf') format('truetype');
}

@font-face {
    font-family: 'orkney';
    font-weight: normal;
    font-style: italic;
    src: url('fonts/orkney/regular-italic.otf') format('truetype');
}

@font-face {
    font-family: 'orkney';
    font-weight: bold;
    font-style: normal;
    src: url('fonts/orkney/bold.otf') format('truetype');
}

html {
    font-size: 10px;
    >* {
        font-size: 1.4rem;
        font-family: 'orkney';
        color: white;
        * {
            font-size: inherit;
            font-family: inherit;
            color: inherit;
        }
    }
}

$border-radius: 4px;

div.App {
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    padding-left: 5px;
    padding-right: 5px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: black;
    overflow: hidden;

    padding-top: 5vh;
    padding-bottom: 5vh;

    div.imageContainer {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: center;
        min-height: 0px;
        min-width: 0px;
        max-width: max-content;

        img.kitten {
            position: relative;
            border-radius: $border-radius;
            overflow: hidden;
            object-fit: contain;
            min-width: 0px;
            min-height: 0px;
            max-width: 100%;
            max-height: 100%;
        }

        a.download {
            cursor: pointer;
            position: absolute;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 6px;
            color: hsl(0, 0%, 10%);
            border: none;
            border-radius: $border-radius;
            background: none;
            background-color: white;
            font-weight: bold;
            text-transform: uppercase;
            text-decoration: none;
            transition-duration: 0.2s;
            flex-shrink: 0;
            bottom: 5px;
            right: 5px;
            transition-duration: 0.2;

            svg {
                $size: 18px;
                min-width: $size;
                max-width: $size;
                min-height: $size;
                max-height: $size;
                object-fit: contain;
                opacity: 0.8;
                box-shadow: 0px 0px 4px hsla(0, 0%, 0%, 0.2);
            }

            &:hover {
                transform: scale(1.05);
            }
            
            &:active {
                transform: scale(0.95);
            }
        }
    }

    div.separator {
        flex: 1;
    }

    strong {
        font-size: 1.8rem;
        margin-top: 20px;
        margin-bottom: 20px;
        flex-shrink: 0;
        text-align: center;
    }

    div.buttons {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        gap: 15px;
    }

    button.more, button.previous {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 40px;
        padding-left: 15px;
        padding-right: 15px;
        color: hsl(0, 0%, 10%);
        border: none;
        border-radius: $border-radius;
        background: none;
        background-color: white;
        font-weight: bold;
        text-transform: uppercase;
        transition-duration: 0.2s;
        flex-shrink: 0;

        &:hover {
            transform: scale(1.05);
        }
        
        &:active {
            transform: scale(0.95);
        }
    }

    span {
        margin-top: 20px;
        font-style: italic;
        font-size: 1.2rem;
        text-align: center;
    }

    img.preload {
        $size: 0px;
        width: $size;
        max-width: $size;
        height: $size;
        max-height: $size;
        opacity: 0;
    }
}
